import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { css } from "goober";
import { Button } from "clutch/src/Button/Button.js";
import { Card } from "clutch/src/Card/Card.js";

import { appURLs } from "@/app/app-urls.mjs";
import { getWikiLink } from "@/game-palworld/components/wiki.style.jsx";
import { useWikiData } from "@/game-palworld/utils/use-wiki-data.mjs";
import Container from "@/shared/ContentContainer.jsx";
import SharedProfile from "@/shared/Profile.jsx";
import { ProfileColumn } from "@/shared/Profile.style.jsx";
import Tilting from "@/shared/Tilting.jsx";

const Styled = {
  avatarCss: () => css`
    img {
      width: var(--sp-20);
      height: var(--sp-20);
      background-color: var(--shade7);
      border-radius: var(--br-sm);
      border: 1px solid var(--shade3-25);

      &:hover {
        border-color: var(--shade2-25);
      }
    }

    .item-caption {
      max-width: 11ch;
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  `,
};

export default function Database() {
  const { t } = useTranslation();
  const { pals, items, buildingTech, buildings } = useWikiData();

  const cards: LandingCardProps[] = [
    {
      title: ["palworld:database.pals.title", "Pals"],
      description: [
        "palworld:database.pals.description",
        "Pals are the eponymous creatures of the game Palworld. Pals help the player, willingly or unwillingly, in the farm, breeding, fighting, or working in factories... including gun factories as shown in the trailer. Pals can also be sold or slaughtered off for food, however, said action is forbidden by law.",
      ],
      cta: ["palworld:database.pals.cta", "See all Pals"],
      ctaLink: "/palworld/database/pals",
      items: pals
        ? Object.values(pals)
            .slice(0, 5)
            .map((pal) => ({
              imgSrc: `${appURLs.CDN_PLAIN}/${pal.imageUri}`,
              label: pal.label,
              wikiLink: `pal:${pal.id}`,
            }))
        : [],
    },
    {
      title: ["palworld:database.palSphere.title", "Pal Spheres"],
      description: (
        <Trans i18nKey="palworld:database.palSphere.description">
          Pal Spheres are items use to capture Pals.
          <br />
          <br />
          Higher level Pals are more difficult to catch, and thus require a
          better type of sphere. You can catch Pals and Alpha bosses and dungeon
          bosses with it. Although it is considered inhumane, you can also catch
          humans with it.
          <br />
          <br />
          If you aim at a Pal from behind, you will get a back bonus and get a
          higher catch chance.
        </Trans>
      ),
      cta: ["palworld:database.palSphere.cta", "See all Spheres"],
      ctaLink: "/palworld/database/spheres",
      items: items
        ? Object.values(items)
            .filter(({ category }) => category === "spheres")
            .slice(0, 5)
            .map((s) => ({
              imgSrc: `${appURLs.CDN_PLAIN}/${s.imageUri}`,
              label: s.label,
              wikiLink: `spheres:${s.id}`,
            }))
        : [],
    },
    {
      title: ["palworld:database.technology.title", "Technology"],
      description: [
        "palworld:database.technology.description",
        "Technology are unlockable recipes in Palworld that require the use of Technology Points.",
      ],
      cta: ["palworld:database.technology.cta", "See all Techs"],
      ctaLink: "/palworld/database/technology",
      items: buildingTech
        ? Object.values(buildingTech)
            .slice(4, 9)
            .map((t) => ({
              imgSrc: `${appURLs.CDN_PLAIN}/${t.imageUri}`,
              label: t.label,
              wikiLink: `technology:${t.id}`,
            }))
        : [],
    },
    {
      title: ["palworld:database.foods.title", "Foods"],
      description: [
        "palworld:database.foods.description",
        "Food and Nutrition is a mechanic in Palworld. It is required to regenerate health and keep the Sanity of your Pals high.",
      ],
      cta: ["palworld:database.foods.cta", "See all Foods"],
      ctaLink: "/palworld/database/foods",
      items: items
        ? Object.values(items)
            .filter(({ category }) => category === "foods")
            .slice(11, 16)
            .map((i) => ({
              imgSrc: `${appURLs.CDN_PLAIN}/${i.imageUri}`,
              label: i.label,
              wikiLink: `foods:${i.id}`,
            }))
        : [],
    },
    {
      title: ["palworld:database.storage.title", "Storage"],
      description: [
        "palworld:database.storage.description",
        "Storage buildings in Palworld. Different buildings will have different sized inventories.",
      ],
      cta: ["palworld:database.storage.cta", "See all Storage"],
      ctaLink: "/palworld/database/buildings/storage",
      items: buildings
        ? Object.values(buildings)
            .filter(({ category }) => category === "storage")
            .slice(0, 5)
            .map((i) => ({
              imgSrc: `${appURLs.CDN_PLAIN}/${i.imageUri}`,
              label: i.label,
              wikiLink: `buildings:${i.id}`,
            }))
        : [],
    },
    {
      title: ["palworld:database.materials.title", "Materials"],
      description: [
        "palworld:database.materials.description",
        "Materials are items that can be used to craft weapons, armor, food and other items in Palworld.",
      ],
      cta: ["palworld:database.materials.cta", "See all Materials"],
      ctaLink: "/palworld/database/materials",
      items: items
        ? Object.values(items)
            .filter(({ category }) => category === "materials")
            .slice(5, 10)
            .map((s) => ({
              imgSrc: `${appURLs.CDN_PLAIN}/${s.imageUri}`,
              label: s.label,
              wikiLink: `materials:${s.id}`,
            }))
        : [],
    },
    {
      title: ["palworld:database.weapons.title", "Weapons"],
      description: [
        "palworld:database.weapons.description",
        "Weapons and guns are your primary combat tools to fight off enemies in Palworld.",
      ],
      cta: ["palworld:database.weapons.cta", "See all Weapons"],
      ctaLink: "/palworld/database/weapons",
      items: items
        ? Object.values(items)
            .filter(({ category }) => category === "weapons")
            .slice(4, 9)
            .map((s) => ({
              imgSrc: `${appURLs.CDN_PLAIN}/${s.imageUri}`,
              label: s.label,
              wikiLink: `weapons:${s.id}`,
            }))
        : [],
    },
  ];

  return (
    <Container>
      <SharedProfile>
        <ProfileColumn className="main flex column gap-sp-4">
          {cards.map((props, i) => (
            <LandingCard key={i} {...props} />
          ))}
        </ProfileColumn>
        <ProfileColumn className="sidebar">
          <Card padding={0}>
            <img
              src={`${appURLs.CDN}//blitz/ui/img/splash/palworld-db-game-info.webp`}
              alt="Palworld"
              width="100%"
            />
            <div className="flex column gap-3 pad-sp-4">
              {[
                [
                  t("palworld:database.gameInfo.developer", "Developer"),
                  "Pocketpair",
                ],
                [
                  t("palworld:database.gameInfo.publisher", "Publisher"),
                  "Pocketpair",
                ],
                [
                  t("palworld:database.gameInfo.platform", "Platform"),
                  ["Windows", "Xbox Series X & S", "Xbox One"],
                ],
                [
                  t("palworld:database.gameInfo.releaseDate", "Release date"),
                  t("common:earlyRelease", "Early Release"),
                  "January 19 2024",
                ],
                [
                  t("palworld:database.gameInfo.genre", "Genre"),
                  t(
                    "palworld:database.gameInfo.survivalCraft",
                    "Survival craft",
                  ),
                ],
                [
                  t("val:patchNotes.modes", "Modes"),
                  [
                    t(
                      "palworld:database.gameInfo.singlePlayer",
                      "Single-player",
                    ),
                    t("palworld:database.gameInfo.onlineCoOp", "Online co-op"),
                    t("palworld:database.gameInfo.localCoOp", "Local co-op"),
                  ],
                ],
                [
                  t("palworld:database.gameInfo.media", "Media"),
                  t("palworld:database.gameInfo.digital", "Digital"),
                ],
              ].map(([left, right]: [string, string | Array<string>]) => (
                <div className="flex justify-between gap-4" key={left}>
                  <div className="type-callout--semi color-shade1">{left}</div>
                  <div style={{ textAlign: "right" }}>
                    {(Array.isArray(right) ? right : [right]).map((i) => (
                      <div className="type-callout--semi" key={i}>
                        {i}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </Card>
        </ProfileColumn>
      </SharedProfile>
    </Container>
  );
}

export function meta() {
  return {
    title: ["palworld:navigation.database", "Palworld Database"],
    description: [
      "palworld:meta.database.description",
      "Discover your go-to destination for Palworld with Blitz! Dive into our extensive Wiki, optimize your gameplay with our advanced Breeding Calculator, and get exclusive tips and tricks. Enhance your Palworld adventure with our user-friendly tools, designed for gamers by gamers. Start mastering Palworld today!",
    ],
    subtitle: true,
  };
}

type LandingCardProps = {
  title: Translation;
  description: Translation | React.ReactElement;
  items: { label: Translation; wikiLink: string; imgSrc: string }[];
  cta: Translation;
  ctaLink: string;
};

const LandingCard = ({
  title,
  description,
  items,
  cta,
  ctaLink,
}: LandingCardProps) => {
  const { t } = useTranslation();

  return (
    <Card className="flex column gap-6">
      <div className="type-h6">{t(...title)}</div>
      <div className="type-callout color-shade1">
        {Array.isArray(description) ? t(...description) : description}
      </div>
      <div className="flex wrap align-center gap-4 justify-between">
        <div className="flex gap-4 scroll-x">
          {items?.length
            ? items.map(({ label, wikiLink, imgSrc }, i) => (
                <a
                  key={i}
                  href={getWikiLink(wikiLink)}
                  className={`${Styled.avatarCss()} flex column align-center gap-sp-1`}
                >
                  <Tilting>
                    <img src={imgSrc} />
                  </Tilting>
                  <div className="type-caption--semi item-caption">
                    {t(...label)}
                  </div>
                </a>
              ))
            : new Array(5).fill(0).map((_, i) => (
                <div
                  key={i}
                  style={{
                    height: "var(--sp-20)",
                    width: "var(--sp-20)",
                    marginBottom: "var(--sp-4)",
                  }}
                >
                  <Card
                    loading
                    style={{ height: "var(--sp-20)", width: "var(--sp-20)" }}
                  />
                </div>
              ))}
        </div>
        <Button type="submit" emphasis="high" href={ctaLink}>
          {t(...cta)}
        </Button>
      </div>
    </Card>
  );
};
